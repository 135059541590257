const Temperature = () => {
  return (
    
      <main className="main px-2">
        <h3 className="text-center">Cooking Temperature</h3>
        <table className="table table-striped-columns mx-auto my-2 w-75">
          <thead>
            <tr className="table-primary">
              <th>Meat</th>
              <th>&deg;C</th>
              <th>&deg;F</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Chicken</td>
              <td>74</td>
              <td>165</td>
            </tr>
            <tr>
              <td>Turkey</td>
              <td>74</td>
              <td>165</td>
            </tr>
            <tr>
              <td>Ground Beef</td>
              <td>71</td>
              <td>165</td>
            </tr>
            <tr>
              <td>Beef Rare</td>
              <td>52-54</td>
              <td>125-130</td>
            </tr>
            <tr>
              <td>Beef Medium Rare</td>
              <td>54-60</td>
              <td>130-140</td>
            </tr>
            <tr>
              <td>Beef Medium</td>
              <td>60-66</td>
              <td>140-150</td>
            </tr>
            <tr>
              <td>Beef Medium Well</td>
              <td>66-71</td>
              <td>150-160</td>
            </tr>
            <tr>
              <td>Beef Well Done</td>
              <td>&gt; 71</td>
              <td>&gt; 160</td>
            </tr>
            <tr>
              <td>Lamb Rare</td>
              <td>52-54</td>
              <td>125-130</td>
            </tr>
            <tr>
              <td>Lamb Medium Rare</td>
              <td>54-60</td>
              <td>130-140</td>
            </tr>
            <tr>
              <td>Lamb Medium</td>
              <td>06-66</td>
              <td>140-150</td>
            </tr>
            <tr>
              <td>Lamb Medium Well</td>
              <td>66-71</td>
              <td>150-160</td>
            </tr>
            <tr>
              <td>Lamb Well Done</td>
              <td>&gt; 71</td>
              <td>&gt; 160</td>
            </tr>
            <tr>
              <td>Pork Rare</td>
              <td>52-54</td>
              <td>125-130</td>
            </tr>
            <tr>
              <td>Pork Medium Rare</td>
              <td>54-60</td>
              <td>130-140</td>
            </tr>
            <tr>
              <td>Pork Medium</td>
              <td>60-66</td>
              <td>14-150</td>
            </tr>
            <tr>
              <td>Pork Well Done</td>
              <td>&gt; 60</td>
              <td>&gt; 150</td>
            </tr>
            <tr>
              <td>Fish</td>
              <td>63</td>
              <td>145</td>
            </tr>
            <tr>
              <td>Leftovers</td>
              <td>74</td>
              <td>165</td>
            </tr>
            <tr>
              <td>Duck Rare</td>
              <td>52-54</td>
              <td>125-130</td>
            </tr>
            <tr>
              <td>Duck Medium Rare</td>
              <td>54-60</td>
              <td>130-140</td>
            </tr>
            <tr>
              <td>Duck Medium</td>
              <td>60-66</td>
              <td>140-150</td>
            </tr>
            <tr>
              <td>Duck Medium Well</td>
              <td>66-71</td>
              <td>150-160</td>
            </tr>
            <tr>
              <td>Duck Well Done</td>
              <td>&gt; 71</td>
              <td>&gt; 160</td>
            </tr>
          </tbody>
        </table>
      </main>
   
  );
};
export default Temperature;
