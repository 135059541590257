import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import fetchOptions from "../../hooks/fetchOptions";
import config from "../../config.json";

import "./user.scss";
import passwordValidator from "../../hooks/passwordValidator";

export default function Profile() {
    const [item, setItem] = useState({});
    const [errors, setErrors] = useState("");
    const [complete, setComplete] = useState(false);
    const context = useContext(UserContext);
    

    useEffect(() => {
        async function loadUser() {
            // console.log("LoadUser:",context.id);
            const url = `${config.api_url}users/${context.id}`;
            const options = fetchOptions({
                method: "GET",
                token: context.token,
            });
            try {
                const response = await fetch(url, options);
                if (!response.ok) {
                    throw new Error(response.status);
                }
                const data = await response.json();
                //console.log("Data:", data);
                setItem(data);
            } catch (err) {
                console.error("Error:" + err.message);
            }
        }
        loadUser();
    }, [context.id, context.token]);

    const handleChange = (e) => {
        setItem((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
    const handleCheck = (e) => {
        setItem((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.checked,
        }));
      
    };
    const handleSubmit = (e) => {
        e.preventDefault();

       
        updateUser();
    };
    const updateUser = async () => {
       
        const url = `${config.api_url}users/${context.id}`;
        const toUpdate = {};
        toUpdate["email"] = item["email"];
        toUpdate["first_name"] = item["first_name"];
        toUpdate["last_name"] = item["last_name"];
        toUpdate["view_shared"] = item["view_shared"];
        if (item["new_password"]) {
            const validator = passwordValidator(
                item["new_password"],
                item["password_confirm"]
            );
            if (validator !== "") {
                setErrors(validator);
                return;
            }
            toUpdate["password"] = item["new_password"];
        }
        const body = JSON.stringify(toUpdate);
        const options = fetchOptions({
            method: "PATCH",
            token: context.token,
            body: body,
        });
        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(response.status);
            }
            //const data = await response.json();
            //console.log("SUCCESS:",data);
           
            setComplete(true);
        } catch (err) {
            console.error("Error:" + err.message);
        }
    };

    return (
        <div className="book">
            <section>
                <h2>Change Profile</h2>
                {!complete && (
                <form onSubmit={handleSubmit}>
                    <label for="email">
                        Email</label>
                        <input
                         className="form-control"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            defaultValue={item.email}
                            onChange={handleChange}
                        />
                   
                    <label for ="first_name" >First Name</label>
                        <input
                            name="first_name"
                            type="text"
                            className="form-control"
                            autoComplete="first-name"
                            required
                            defaultValue={item.first_name}
                            onChange={handleChange}
                        />
                  
                    <label for ="last_name" >
                        Last Name</label>
                        <input
                            name="last_name"
                            type="text"
                            className="form-control"
                            autoComplete="last-name"
                            required
                            defaultValue={item.last_name}
                            onChange={handleChange}
                        />
                    
                    <label for = "new_password" >
                        New Password</label>
                        <input
                            name="new_password"
                            type="password"
                            className="form-control"
                            autoComplete="new-password"
                            onChange={handleChange}
                        />
                   
                    <label for = "password_confirm" >
                        Reenter Password</label>
                        <input
                            name="password_confirm"
                            className="form-control"
                            autoComplete="new-password"
                            type="password"
                            onChange={handleChange}
                        />
                   <div class="form-check px-5 mx-5">
                    <label htmlFor="view_shared" className="form-check-label">
                        View Shared Recipes
                        </label>
                        <input
                            type="checkbox"
                            name="view_shared"
                            className="form-check-input"
                            id="view_shared"
                            defaultChecked={item.view_shared}
                            onChange={handleCheck}
                        />
                        </div>
                   
                    <p className="error">{errors}</p>
                    <button class="btn btn-primary">Update</button>
                </form>
                )}
                {complete && (<h3 role="alert">Your Profile has been updated. Logout and Login to Confirm Changes</h3>)}
            </section>
        </div>
    );
}
