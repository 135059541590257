import CatSpinner from "./CatSpinner";
import React from "react";
import { UserContext } from "../../context/UserContext";
import fetchOptions from "../../hooks/fetchOptions";
import noPhoto from "../../images/nophoto.svg";
const config = require("../../config.json");

class RecipeForm extends React.Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            displayPhoto: noPhoto,
            recipe: { name: "", category_id: 1, url: "", content: "" },
        };
        this.handleChange = this.handleChange.bind(this);
        this.photoError = this.photoError.bind(this);
    }
    async componentDidMount() {
        try {
            const id = this.props.rid;
            const catid = this.context.catId;
            if (catid) {
                this.setState({
                    recipe: {
                        name: "",
                        category_id: catid,
                        url: "",
                        content: "",
                    },
                });
            }
            if (!id) {
                return;
            }
            const url = `${config.api_url}recipes/${id}`;
            const options = fetchOptions({
                method: "GET",
                token: this.context.token,
            });

            const resp = await fetch(url, options);
            if (!resp.ok) {
                throw new Error(resp.statusText);
            }
            const data = await resp.json();
            
            this.setState({ recipe: data });
        } catch (e) {}
    }
    handleChange(event) {
        this.setState({ recipe: { [event.target.name]: event.target.value } });
    }

    photoError(e) {
        e.preventDefault();
        this.setState({ displayPhoto: noPhoto });
        e.target.src = noPhoto;
    }
    render() {
        const recipe = this.state.recipe;
        let showPhoto = noPhoto;
        if (recipe.photo) {
            showPhoto = `${config.api_url}photos/${recipe.photo}`;
        }
        return (
            <form onSubmit={this.props.onSubmit}>
                <img
                    src={showPhoto}
                    alt="food"
                    className="photo"
                    onError={this.photoError}
                />
                <div className="w-75 mx-auto">
                    <label className="form-label" htmlFor="recipe-name">
                        Recipe Name:
                    </label>
                    <input
                        type="text"
                        id="recipe-name"
                        name="name"
                        className="form-control"
                        placeholder="Recipe Name"
                        value={recipe.name}
                        onChange={this.handleChange}
                    />
              
                
                <CatSpinner
                    catId={recipe.category_id}
                    handleChange={this.handleChange}
                />
               
                    <label className="form-label" htmlFor="url">
                        Web Page URL:
                    </label>
                    <input
                        type="url"
                        id="url"
                        name="url"
                        className="form-control"
                        placeholder="URL"
                        defaultValue={recipe.url}
                    />
              
                <input type="hidden" name="photo" defaultValue={recipe.photo} />
                
                <label className="form-label" htmlFor="upload">
                        Photo:
                    </label>
                    <input
                        type="file"
                        className="form-control"
                        name="upload"
                        onChange={this.props.uploadPhoto}
                        accept="images/*"
                    />
               

                <div>
                    <label className="form-label" htmlFor="content">
                        Enter Recipe/Link Comment
                    </label>
                    <textarea
                        id="content"
                        name="content"
                        className="form-control"
                        placeholder="Recipe"
                        rows="10"
                        defaultValue={recipe.content}
                    ></textarea>
                </div>

                <div className="text-center p-2">
                    <button id="Submit" className="btn btn-primary">Save</button>
                </div>
                </div>
            </form>
        );
    }
}

export default RecipeForm;
