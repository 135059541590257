

import { useLocation } from 'react-router-dom';
import NavMain from './NavMain.jsx';
//import logo from '../../images/logo2024.svg';
function WCHeader({removeUser}) {
  
  let nav=null;
  const search = useLocation().search;
  const webview = new URLSearchParams(search).get('webview');
   if (webview)
      return (<div className='voidheader' />);
  //if (con) {
    //console.log("User is Logged in as:",con);
    nav=(<NavMain removeUser={removeUser} />);
  //}
  return (
    
      <header>      
          {nav}
      </header>
   
  );
}
export default WCHeader;