import { useContext} from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
//import { useDetectOutsideClick } from "./useDetectOutsideClick";
import { FaSignOutAlt, FaUser,FaUserAlt } from "react-icons/fa";
export default function DropDown(props) {
    //const dropdownRef = useRef(null);
    const context = useContext(UserContext);
   
    // const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
    // const onClick = () => setIsActive(!isActive);
    const logOut =(e) => {
        e.preventDefault();
        props.removeUser();
       // this.setState({ refresh: true });
        //this.context = {};
        window.location ="/";
    }
    return (
<li className="nav-item dropdown me-3">
    <a href="javascript.void();" className="nav-link  dropdown-toggle"
    id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    <FaUser />
        <span className="menuLabel">{context?.firstName}</span></a>
        <ul className="dropdown-menu short-drop"  aria-labelledby="navbarDropdownMenuLink">

            <li className="short-drop">
            <Link to="user/profile" className="dropdown-item">
                        <FaUserAlt />
                        <span className="menuLabel">Profile</span>
                        </Link>
            </li>

            <li>
            <a href="/" onClick={logOut} className="dropdown-item">
            
                        <FaSignOutAlt />
                        <span className="menuLabel">Logoff</span>
                       
                        </a>
            </li>
            
        </ul>

</li>

        // <div className="menu-container">
        //     <button onClick={onClick} className="menu-trigger">
              
        //       <FaUser />
        //         <span className="menuLabel">{context?.firstName}</span>
        //     </button>
        //     <nav
        //         ref={dropdownRef}
        //         className={`menu ${isActive ? "active" : "inactive"}`}
        //     >
        //         <ul>
        //             <li>
        //             <Link to="/user/profile">Profile</Link>
        //             </li>
        //             <li className="blue">
        //                 Test
        //                 <a href="/" onClick={logOut}>Log off</a>
        //             </li>
                    
        //         </ul>
        //     </nav>
        // </div>
    );
}
