import React from "react";
import { UserContext } from "../../context/UserContext";
import { Link } from "react-router-dom";
import { FaBlog, FaHome, FaList, FaPlusCircle, FaQuestion, FaSignInAlt, FaThermometer} from "react-icons/fa";
import DropDown from "./DropDown";
import "./menu.scss";
import logo from '../../images/logo2024.svg';
export default class NavMain extends React.Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
       
        this.logOut = this.logOut.bind(this);

        //this.state = { refresh: false };
        this.state = { search: "" };
    }
    logOut(e) {
        e.preventDefault();
      
        if(this.props.removeUser) {
           
            this.props.removeUser();
        }
       
        this.context = {};
        window.location = "/";
    }


    render() {
        const shopping = `/shoppinglist/${this.context?.id}`;
        let loggedMenu = "";
        let userMenu = "";
       
        
        if(this.context) {
            loggedMenu = (
                <>

<li className="nav-item" title="Table of Contents">
                        <Link to="/category" className="nav-link">
                            <FaHome />
                            <span className="menuLabel">Table of Contents</span>
                        </Link>
                    </li>
                    <li className="nav-item" title="Add a recipe">
                        <Link to="/recipe/create" className="nav-link">
                            <FaPlusCircle />
                            <span className="menuLabel">Add a Recipe</span>
                        </Link>
                    </li>
                    <li className="nav-item" title="Shopping List">
                        <Link to={shopping} className="nav-link">
                            <FaList />
                            <span className="menuLabel">Shopping List</span>
                        </Link>
                    </li>
                   
                    </>
            );
            userMenu=(<DropDown {...this.props} />);
        }
        else {
            loggedMenu = "";
            userMenu=( <li className="nav-item me-3" title="Log In">
                <Link to = "/login" className="nav-link">
                <FaSignInAlt />
                <span className="menuLabel">Log In</span>
                </Link>
            </li> );
        }

        return (
            <nav className="navbar navbar-expand ms-2 pe-5">
                <div className="container-fluid me-5">
                <Link to= "/home" className="nav-brand">
                    <img className="logo" src = {logo} alt="Worth Cooking Logo"/>
                    </Link>
                    <div >
                    <ul className="navbar-nav">
                     
                    {loggedMenu}
                    <li className="nav-item" title="Cook Temperature">
                        <Link to ="/temperature" className="nav-link">
                        <FaThermometer />
                        <span className="menuLabel">Cook Temperature</span>
                        </Link>
                    </li>
                    <li className="nav-item" title="Blog">
                        <Link to ="/blog" className="nav-link">
                        <FaBlog />
                        <span className="menuLabel">Blog</span>
                        </Link>
                    </li>
                    <li className="nav-item" title="Help">
                        <Link to="/help" className="nav-link">
                            <FaQuestion />
                            <span className="menuLabel">Help</span>
                        </Link>
                    </li>
                   
                   
                   
                    {userMenu}
                  
                 
                   
                </ul>
                </div>
                </div>
            </nav>
        );
    }
}
