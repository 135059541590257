import {  Route, Switch } from "react-router-dom";
import Recipes from "./Recipes/Recipes";
import Recipe from "./Recipes/Recipe";
import RecipeAdd from "./Recipes/AddRecipe";
import EditRecipe from "./Recipes/EditRecipe";
import Category from "./Category/Category";
import Register from "./User/Register";
import Forgot from "./User/Forgot";
import Login from "./User/Login";
import ShoppingList from "./ShoppingList/ShoppingList";
import Reset from "./User/Reset";
import Profile from "./User/Profile";
import PrivateRoute from "./PrivateRoute";
import Help from "./Help/help";
import Blog from "./Blog/blog";
import QRRecipe from "./QRRecipe/QRRecipe";
import  Splash  from "./User/Splash";
import Home from "./Home/homeNew";
import Temperature from "./Temperatures/Temperature";
export default function Routes(props) {
    let home;
    if (props.user) {
        //console.log("User is:",props.user);
        home = <Splash data={props} />

    }
    else {
        home = <Login data={props} />
    }
    return(
        <Switch>
            <Route exact path="/temperature"><Temperature/></Route>
            <Route exact path="/home" data={props} ><Home /> </Route>
            <Route exact path="/splash">{home}</Route>
            <Route exact path="/" data={props}><Home /></Route>
            <Route exact path="/login"><Login data={props}/></Route>
            <Route exact path="/register"><Register /></Route>
            <PrivateRoute exact path="/category" component={Category} data={props}/>
            <PrivateRoute exact path="/category/:catid" component={Recipes} data={props} />
            <PrivateRoute exact path="/recipe/create"  component={RecipeAdd} data={props}/>
            <PrivateRoute exact path="/recipe/" component={Recipes} data={props} />
            <PrivateRoute exact path="/recipe/:id/edit"  component={EditRecipe} data={props} />
            <PrivateRoute path="/recipe/:id"  component={Recipe}  />
            <PrivateRoute path="/shoppinglist/:id" component={ShoppingList} />
            <Route exact path="/user/forgot"><Forgot /></Route>
            <Route exact path="/user/reset"><Reset /></Route>
            <Route exact path="/help"><Help /></Route>
            <Route exact path="/blog"><Blog /></Route>
            <Route exact path="/splash"><Splash  data={props}/></Route>
            <PrivateRoute exact path="/user/profile" component={Profile} />
            <Route path ="/qrcode"><QRRecipe /></Route>
            

        </Switch>
    );
}