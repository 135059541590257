import plate1 from '../../images/plates-6918225_1280.jpg';
import badge from '../../images/google-play-badge.png';
import temperature from '../../images/temperature.jpg';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import RecipeOfTheDay from '../Blog/RecipeOfTheDay';
const HomeNew = () => {

    return(
        <div className="main">
            <h3 className="text-center py-3 px-3">Welcome to Worth Cooking!</h3>
            <img src={plate1} alt="Plate of Spaghetti" className="img-fluid float-start w-25 me-3" />
           
            <p className="my-2  mx-5 bg-light shadow">Greeting! This is Worth Cooking. Your personal resource for recipes.
                Think of us as your cookbook for modern times.  Your recipes are at your fingertips.
                You can also write a shopping list for your next great recipe.
              
                I will also be sharing some of my recipes and providing some cooking tips.  
                 Download the App for android to take Worth Cooking with you! </p>
               <a href="https://play.google.com/store/apps/details?id=ca.worthconsulting.worthcooking"
               target="_blank" rel="noreferrer">
            <img src={badge} alt="Google Play" className="badgey my-5" />
            </a>
            <div className="row">
                <Link to ="/blog" className="col-md-3 offset-md-6 link-underline link-underline-opacity-0 col-md-7">
                <h4 className="bg-light shadow  p-2  text-center rotateLeft shrinkwrap">
                    Check Out the Worth Cooking Blog</h4></Link>
                    </div>
           
          <div className="col-md-5 text-center">
            <Link to="/Temperature" className="shrinkwrap link-underline link-underline-opacity-0" >
            
            <img src={temperature} alt="Cooking Temperature" width="150" className="mx-auto text-center"/>
          
            <h5 className="mx-auto text-center shrinkwrap " >Cooking Temperatures</h5>
           
            </Link>
            </div>
            <div className='my-3'><h2 className='text-center  bg-dark text-white shrinkwrap mx-auto p-2'>Recipe of the Week</h2>
            <RecipeOfTheDay />
            </div>
          
        </div>
    );
};
export default HomeNew;