import React, { useState, useEffect } from "react";
import "./blog.scss";
function RecipeOfTheDay() {
  const [recipe, setRecipe] = useState(null);

  useEffect(() => {
    const fetchRecipe = async () => {
      try {
        const response = await fetch(
          process.env.PUBLIC_URL + `/recipes/chicken_soup.html`
        );
        if (response.ok) {
          const data = await response.text();
          setRecipe(data);
        } else {
          setRecipe("<h3>No Recipe Today</h3>");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchRecipe();
  }, []);
  return <span dangerouslySetInnerHTML={{ __html: recipe }} />;
}
export default RecipeOfTheDay;
